<template>
 <div>
  <svg height="188.798" viewBox="0 0 188.798 188.798" width="188.798" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradientxx" x1="0.574" x2="0.5" y1="-0.097" y2="1">
     <stop offset="0" stop-color="#2fed92"/>
     <stop offset="1" stop-color="#06c167"/>
    </linearGradient>
   </defs>
   <g id="icon_logo_ubereats" transform="translate(-1632 -1776)">
    <rect :fill="background" data-name="Rectangle 21" height="188.798" id="Rectangle_21" transform="translate(1632 1776)" width="188.798"/>
    <g id="Uber_Eats_2020_logo" transform="translate(1669.5 1828.8)">
     <path d="M607.589,24.8a8.9,8.9,0,1,0-8.9,8.786,8.771,8.771,0,0,0,8.9-8.786m7.263-14.386v28.8h-7.4v-2.6a14.579,14.579,0,0,1-9.372,3.327,15.251,15.251,0,0,1-15.6-15.136,15.28,15.28,0,0,1,15.6-15.136,14.579,14.579,0,0,1,9.372,3.327V10.415h7.4Zm24.6,22.258h-5.576c-1.687,0-2.788-.726-2.788-2.273V16.929h8.341V10.415h-8.341v-8.2h-7.474v8.2h-5.623v6.537h5.623v15.3c0,3.866,2.788,6.935,7.8,6.935h8.013V32.674Zm16.869,7.263c8.552,0,13.378-4.006,13.378-9.536,0-3.936-2.858-6.865-8.833-8.13l-6.326-1.265c-3.655-.656-4.827-1.335-4.827-2.671,0-1.734,1.757-2.788,5.014-2.788,3.538,0,6.115.937,6.865,4.124H669c-.4-6-4.826-10-13.777-10-7.732,0-13.167,3.14-13.167,9.208,0,4.194,3,6.935,9.442,8.271l7.052,1.593c2.788.539,3.538,1.265,3.538,2.39,0,1.8-2.109,2.929-5.506,2.929-4.264,0-6.724-.937-7.661-4.124h-7.474c1.1,6,5.647,10,14.878,10M552.6.2h27.9V6.854h-20.36V16.39h19.822v6.467H560.144V32.51H580.5v6.654H552.6Z" data-name="Path 557" fill="url(#linear-gradientxx)" id="Path_557" transform="translate(-553.1 44.985)"/>
     <path d="M115.828,15.781V10.556h-1.992a7.769,7.769,0,0,0-6.912,3.725V10.767h-5.67V39.14h5.717V23.02c0-4.4,2.718-7.24,6.467-7.24Zm-41.142,6.56a9.079,9.079,0,0,1,8.833-7.24,8.949,8.949,0,0,1,8.786,7.24H74.686Zm8.95-12.113A14.729,14.729,0,0,0,68.782,24.965c0,8.458,6.677,14.808,15.37,14.808A15.2,15.2,0,0,0,96.616,33.7l-4.147-3a10.016,10.016,0,0,1-8.341,4.17,9.643,9.643,0,0,1-9.583-8.06H98.069V24.989c.023-8.482-6.092-14.761-14.433-14.761M50.928,34.876a9.841,9.841,0,1,1,9.911-9.84,9.885,9.885,0,0,1-9.911,9.84M35.347,39.164h5.67V35.6A14.516,14.516,0,0,0,51.326,39.82a14.835,14.835,0,1,0,0-29.662,14.462,14.462,0,0,0-10.262,4.217V.2H35.347V39.164ZM15.221,34.595c5.506,0,9.747-4.17,9.747-10.356V.2h5.951V39.14h-5.9V35.532a14.51,14.51,0,0,1-10.5,4.288C6.013,39.82-.5,33.751-.5,24.567V.2H5.475V24.239c0,6.3,4.194,10.356,9.747,10.356" data-name="Path 558" fill="#142328" id="Path_558" transform="translate(0 0)"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>